
import { Vue } from 'vue-class-component'
import { utils } from 'ethers'
import { useWeb3 } from '@/store/Web3'

export default class HelloWorld extends Vue {
  Web3 = useWeb3()
  mintAmount = 0

  get canMint (): boolean {
    return !this.Web3.isPaused || this.canWhitelistMint
  }

  private get canWhitelistMint (): boolean {
    return this.Web3.isWhitelistMintEnabled && this.Web3.isUserInWhitelist && (this.Web3.alreadyMintedAmount < this.Web3.amountAllowed)
  }

  get formattedPrice (): string {
    return utils.formatEther(this.Web3.tokenPrice * BigInt(this.mintAmount)).toString()
  }

  changeAmount (off: number) {
    if (this.mintAmount + off >= 0 && this.mintAmount + off <= this.Web3.maxMintAmountPerTx) {
      this.mintAmount += off
    }
  }

  async mint (): Promise<void> {
    if (!this.Web3.isPaused) {
      await this.Web3.mintTokens(this.mintAmount)
      return
    }
    await this.Web3.whitelistMintTokens(this.mintAmount)
    this.mintAmount = 0
  }
}
