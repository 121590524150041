
import { Vue } from 'vue-class-component'
import { useWeb3 } from '@/store/Web3'

export default class HelloWorld extends Vue {
  Web3 = useWeb3()

  get isSaleOpen (): boolean {
    return (this.Web3.isWhitelistMintEnabled || !this.Web3.isPaused) && !this.Web3.isSoldOut
  }
}
